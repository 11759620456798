/* eslint-disable */

import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';

import {
  SET_CURRENT_CALC_SECTION,
  SET_QUESTIONS_DATA,
  SET_COMPANY_QUESTIONS_DATA,
  SET_GLOBAL_QUESTIONS_DATA,
  SET_GLOBAL_INPUT_TYPES,
  SET_CALCULATORS_LIST,
  SET_COMPANY_DATA,
  SET_CALCULATOR_ANSWER,
  SET_GLOBAL_CALCULATOR_ANSWERS,
  SET_SCORE,
  SET_LAST_VISITED_INDEX,
  SET_CALCULATOR_DATA,
  SET_CALCULATOR_NAME,
  SET_CHART_DATA,
} from '../types';
import {
  CalculatorsUrls,
  DefaultCalculatorPrices,
  CalculatorsListData,
  CALC_TYPES,
} from '../../constants';

import CalculatorsContext from './calculatorsContext';
import calculatorsReducer from './calculatorsReducer';

const colorScheme = {
  Carbon: '#12491D',
  Environmental: '#2DA131',
  Social: '#01A0C6',
  Governance: '#2E58A6',
  Economic: '#2E58A6',
  globalEnvironmental: '#2DA131',
  globalSocial: '#01A0C6',
  globalEconomic: '#2E58A6',
};
const carbonCalculators = {
  company: [
    {
      name: 'Scope 1',
      type: 'scope1',
      color: colorScheme.Carbon,
      value: 0,
      total: 5,
    },
    {
      name: 'Scope 2',
      type: 'scope2',
      color: colorScheme.Carbon,
      value: 0,
      total: 2,
    },
    {
      name: 'Scope 3',
      type: 'scope3',
      color: colorScheme.Carbon,
      value: 0,
      total: 19,
    },
  ],
};

const CalculatorsState = ({ children }) => {
  const initialState = {
    questions: [],
    carbonStructure: {
      activities: [],
      types: [],
      units: [],
      classes: [],
    },
    selectedCalculators: [],
    carbonSources: {
      product: [],
      package: [],
    },
    company: {},
    fullChartData: [],
    chartData: [],
    lastVisitedIndex: -1,
    // calculatorAnswers: { ...DefaultCalculatorAnswers },
    calculatorAnswers: {},
    companyAnswers: {},
    score: {
      environmental: 0,
      social: 0,
      governance: 0,
      total: 0,
      productTotal: 0,
      packageTotal: 0,
      companyTotal: 0,
      testYear: new Date().getFullYear(),
    },
    produсtMaterials: [],
    packageMaterials: [],
    globalInputTypes: {},
    calculatorPrices: { ...DefaultCalculatorPrices },
    calculatorCost: 0,
    calculatorName: '',
    chartCreated: false,
    TT_CompanyID: null,
  };

  // const [filesList, setFilesList] = useState([]);
  const [state, dispatch] = useReducer(calculatorsReducer, initialState);

  const createChartData = () => {
    const names = state.selectedCalculators.map((calc) => calc.name);

    const chartData = [];
    let fullChartData = [];

    if (names.length) {
      names.forEach((name) => {
        if (name === 'scope1') chartData.push(...carbonCalculators.company);

        if (name === 'local') {
          const localChartData = state.sortedData
            .map((value) => ({
              name: value.name,
              type: 'local',
              color: colorScheme[value.esg],
              value: 0,
              total: value.questions.length,
            }))
            .slice(1);
          chartData.push(...localChartData);
        }
        fullChartData = chartData.map((sec) => ({
          ...sec,
          value: sec.total,
        }));

        if (name === 'global' && state.globalQuestions) {
          const globalChartData = state.globalQuestions.map((d) => {
            const totalQuestions = d.categories.reduce(
              (total, category) => total + category.questions.length,
              0
            );
            const newDim = {
              name: d.name,
              type: 'global',
              color: colorScheme[d.code],
              value: 0,
              total: totalQuestions,
              id: d.id,
              children: d.categories.map((c) => {
                const newobj = {
                  name: c.name,
                  color: colorScheme[d.code],
                  value: 0,
                  total: c.questions ? c.questions.length : 1,
                  id: c.id,
                };
                return newobj;
              }),
            };
            return newDim;
          });
          chartData.push(...globalChartData);
          fullChartData = globalChartData.map((dimention) => ({
            ...dimention,
            children: [
              ...dimention.children.map((category) => ({
                ...category,
                value: category.total,
              })),
            ],
            value: dimention.total,
          }));
        }
      });
    }

    return { chartData, fullChartData };
  };

  useEffect(() => {
    if (!state.sortedData || !state.selectedCalculators.length) return;

    const payload = {};
    const { fullChartData, chartData } = createChartData();
    payload.fullChartData = fullChartData;
    payload.chartData = chartData;

    if (chartData.length && fullChartData.length) payload.chartCreated = true;

    dispatch({
      type: SET_CHART_DATA,
      payload,
    });
  }, [state.selectedCalculators, state.sortedData, state.globalQuestions]);

  const getlocalCalculatorAnswersCount = () => {
    const { calculatorName, carbonSources, company, calculatorAnswers } = state;

    const result = {};
    if (['carbon', 'local'].includes(calculatorName)) {
      result.company = Object.keys(company).reduce(
        (res, key) => res + (company[key] ?? []).filter((activ) => activ.filled).length ?? 0,
        0
      );
    }
    if (['carbon', 'local', 'product', 'package'].includes(calculatorName)) {
      result.product = carbonSources.product.length;
      result.package = carbonSources.package.length;
    }
    if (calculatorName === 'local') {
      result.local = Object.keys(calculatorAnswers).filter((key) => calculatorAnswers[key]).length;
    }
    return result;
  };

  const isAnyAnswerExists = () => {
    const calcRes = getlocalCalculatorAnswersCount();
    return !!Object.keys(calcRes).filter((key) => calcRes[key] > 0)?.length;
  };

  const changeQuestionsData = (data = []) => {
    const sortedData = [...data].sort((a, b) => a.esgPosition - b.esgPosition).slice(2, -1);
    const questions = sortedData
      .reduce((res, v) => {
        v.questions.forEach((question) => {
          res.push({
            ...question,
            body: (question.body || '').replace(/\n/, '<br/>'),
            categories: [v.esg, v.name],
          });
        });
        return res;
      }, [])
      .filter((question) => question.title);

    dispatch({
      type: SET_QUESTIONS_DATA,
      payload: { questions, sortedData },
    });
  };

  const setCurrentCalcSection = (data) => {
    dispatch({
      type: SET_CURRENT_CALC_SECTION,
      payload: data,
    });
  };

  const changeGlobalQuestionsData = (data = []) => {
    dispatch({
      type: SET_GLOBAL_QUESTIONS_DATA,
      payload: data,
    });
  };

  const changeGlobalInputTypesData = (data) => {
    const { GlobalVariants } = data;
    dispatch({
      type: SET_GLOBAL_INPUT_TYPES,
      payload: {
        globalInputTypes: JSON.parse(GlobalVariants),
      },
    });
  };

  const setCalculatorName = (name = '') => {
    if (name) window.sessionStorage.setItem('SELECTED_CALCULATOR_NAME', name);
    dispatch({
      type: SET_CALCULATOR_NAME,
      payload: name,
    });
  };

  const changeCompanyQuestionsData = (data = []) => {
    const payload = {};
    payload.companyQuestions = data;
    dispatch({
      type: SET_COMPANY_QUESTIONS_DATA,
      payload,
    });
  };

  const setCalculatorsList = (calculators = []) => {
    const allCalculators = [...calculators];
    const selectedCalculators = allCalculators
      .map((name) => ({
        name,
        url: CalculatorsUrls[name],
        clearUrl: CalculatorsUrls[name].slice(0, CalculatorsUrls[name].lastIndexOf('/')),
      }))
      .filter((calculator) => calculator.url);
    dispatch({
      type: SET_CALCULATORS_LIST,
      payload: { selectedCalculators },
    });
  };

  const createCalculatorList = (name) => {
    const newCalculators = {
      ...JSON.parse(JSON.stringify(CalculatorsListData)),
    };
    if (!newCalculators[name]) return;
    const selected = newCalculators[name].children;
    setCalculatorName(name);
    setCalculatorsList(selected);
  };

  const changeCalculatorAnswer = (name, value) => {
    const { questions, calculatorAnswers, chartData } = state;
    const question = questions.find((q) => q.code === name);
    const category = question.categories[question.categories.length - 1];

    calculatorAnswers[name] = value;

    const count = questions.filter(
      (q) => q.categories[q.categories.length - 1] === category && !!calculatorAnswers[q.code]
    ).length;
    const chartIndex = chartData.findIndex((v) => v.name === category);

    chartData[chartIndex].value = count;

    dispatch({
      type: SET_CALCULATOR_ANSWER,
      payload: {
        answers: calculatorAnswers,
        chartData,
      },
    });
  };

  const changeGlobalCalculatorAnswer = ({ dimention, category, globalAnswers }) => {
    const { globalQuestions, chartData } = state;
    if (!chartData.length > 0) return;
    const { id } = globalQuestions[dimention].categories[category];
    const newCategory = globalQuestions[dimention].categories[category];
    const countQuest = newCategory.questions.reduce((res, q) => {
      const isFilled =
        !!globalAnswers[q.id] || q.subQuestions.some((sq) => !!globalAnswers[sq.code]);
      return isFilled ? res + 1 : res;
    }, 0);
    const elem = chartData[dimention].children.find((dt) => dt.id === newCategory.id);
    if (elem) elem.value = countQuest;

    const newDimention = chartData[dimention];
    const countCat = newDimention.children.reduce((res, c) => res + c.value, 0);
    chartData[dimention] = {
      ...newDimention,
      value: countCat,
    };

    const newChartIndex = chartData[dimention].children.findIndex((v) => v.id === id);

    dispatch({
      type: SET_GLOBAL_CALCULATOR_ANSWERS,
      payload: {
        chartData,
        chartIndex: newChartIndex < 0 ? null : newChartIndex,
      },
    });
  };

  const setCompanyData = (questionId, activities) => {
    const { chartData, company, companyQuestions } = state;
    const newChartData = chartData.length === 0 ? createChartData().chartData : chartData;
    const newCompany = {
      ...company,
      [questionId]: [...activities],
    };
    const { code, questions } = companyQuestions.find((scp) =>
      scp.questions.some((q) => q.id === questionId)
    );
    const index = chartData.findIndex((v) => code === v.type);
    newChartData[index].value = 0;
    newChartData[index].total = questions.length;

    questions.forEach((q) => {
      newChartData[index].value += newCompany[q.id]?.some((c) => c.filled) ? 1 : 0;
    });

    dispatch({
      type: SET_COMPANY_DATA,
      payload: {
        company: newCompany,
        chartData: [...newChartData],
      },
    });
  };

  const setDraftData = (data) => {
    const payload = {};
    const { chartData } = createChartData();
    const { questions, companyQuestions } = state;
    const { globalQuestions } = state;
    const globalAnswers = data.globalAnswers ?? {};
    const productAnswers = data.productAnswers ?? [];
    const packageAnswers = data.packageAnswers ?? [];

    const companyAnswers =
      Object.keys(data?.companyAnswers ?? {}).length > 0
        ? data.companyAnswers
        : {
            ...initialState.company,
          };
    const esgAnswers = data.answers || {};

    if (
      data.type === CALC_TYPES.LOCAL ||
      data.type === CALC_TYPES.CARBON ||
      data.type === CALC_TYPES.PROJECT
    ) {
      // ESG answers --- !!! fill chartData first!
      if (data.type === CALC_TYPES.LOCAL) {
        chartData.forEach((cd) => {
          const category = cd.name;
          const count = questions.filter(
            (q) => q.categories[q.categories.length - 1] === category && !!esgAnswers[q.code]
          ).length;

          /* eslint-disable-next-line no-param-reassign */
          cd.value = count;
        });
      }

      // Company answers
      Object.keys(companyAnswers).forEach((key) => {
        const filled = companyAnswers[key].some((ca) => ca.data || ca.info);
        if (filled && companyQuestions) {
          const { code } = companyQuestions.find((scp) => scp.questions.some((q) => q.id === key));
          const index = chartData.findIndex((v) => code === v.type);
          chartData[index].value += 1;
        }
      });

      payload.chartData = chartData;
    }

    if (data.type === CALC_TYPES.GLOBAL) {
      const newChartData = chartData.map((dimention) => {
        const qdim = globalQuestions.find((d) => d.id === dimention.id);
        const newChildren = dimention.children.map((category) => {
          const qcat = qdim.categories.find((c) => c.id === category.id);
          const value = qcat.questions.reduce((res, q) => {
            const isFilled =
              !!globalAnswers[q.id] || q.subQuestions.some((sq) => !!globalAnswers[sq.code]);
            return isFilled ? res + 1 : res;
          }, 0);
          return {
            ...category,
            value,
          };
        });
        const value = newChildren.reduce((sum, category) => sum + category.value, 0);
        return {
          ...dimention,
          children: newChildren,
          value,
        };
      });
      payload.chartData = newChartData;
    }

    payload.calculatorAnswers = esgAnswers;
    payload.productAnswers = productAnswers;
    payload.packageAnswers = packageAnswers;
    payload.globalAnswers = globalAnswers;
    payload.companyAnswers = Object.keys(companyAnswers).reduce((result, key) => {
      const components = companyAnswers[key].length
        ? companyAnswers[key]
        : initialState.company[key];

      return { ...result, [key]: components };
    }, {});

    dispatch({
      type: SET_CALCULATOR_DATA,
      payload,
    });
  };

  const setScore = (score) => {
    dispatch({
      type: SET_SCORE,
      payload: score,
    });
  };

  const changeLastVisitedIndex = (index) => {
    dispatch({
      type: SET_LAST_VISITED_INDEX,
      payload: index,
    });
  };

  return (
    <CalculatorsContext.Provider
      value={{
        state,
        questions: state.questions,
        companyQuestions: state.companyQuestions,
        globalQuestions: state.globalQuestions,
        globalInputTypes: state.globalInputTypes,
        selectedCalculators: state.selectedCalculators,
        carbonSources: state.carbonSources,
        company: state.company,
        fullChartData: state.fullChartData,
        calculatorAnswers: state.calculatorAnswers,
        globalAnswers: state.globalAnswers,
        score: state.score,
        lastVisitedIndex: state.lastVisitedIndex,
        // filesList,
        calculatorName: state.calculatorName,
        chartData: state.chartData,
        calculatorCost: state.calculatorCost,
        calculatorPrices: state.calculatorPrices,
        chartCreated: state.chartCreated,
        section: state.section,
        changeCompanyQuestionsData,
        changeGlobalQuestionsData,
        changeGlobalInputTypesData,
        changeQuestionsData,
        setCalculatorsList,
        createCalculatorList,
        setCompanyData,
        changeCalculatorAnswer,
        changeGlobalCalculatorAnswer,
        getlocalCalculatorAnswersCount,
        isAnyAnswerExists,
        setDraftData,
        setScore,
        changeLastVisitedIndex,
        // setFilesList,
        setCalculatorName,
        setCurrentCalcSection,
      }}
    >
      {children}
    </CalculatorsContext.Provider>
  );
};

CalculatorsState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CalculatorsState;
