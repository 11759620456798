/* eslint-disable react/require-default-props */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ConfDocSvg } from '../../assets/icons/confDoc.svg';
import styles from './DropArea.module.scss';

const DropArea = ({ onDrop }) => {
  const [drag, setDrag] = useState(false);

  const onDragStartHandler = (e) => {
    e.preventDefault();
    setDrag(true);
  };

  const onDragLeaveHandler = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const onDropHandler = (e) => {
    e.preventDefault();
    const filesList = [...e.dataTransfer.files];
    onDrop(filesList);
  };

  return (
    <>
      <div
        className={styles.dragndropWrapper}
        onDragStart={onDragStartHandler}
        onDragOver={onDragStartHandler}
        onDragLeave={onDragLeaveHandler}
        onDrop={onDropHandler}
      >
        <h4>
          <span>
            <ConfDocSvg />
          </span>
          {drag ? 'Drop files here' : 'Drag files here'}
        </h4>
        <p>You can add file with maximum size 100 MB</p>
      </div>
    </>
  );
};

DropArea.propTypes = {
  onDrop: PropTypes.func,
};

export default DropArea;
