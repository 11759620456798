import React, { useLayoutEffect, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ProgressCircle.module.scss';

const sizes = [
  { height: 1000, min: 204, max: 150 },
  { height: 960, min: 203.5, max: 150 },
  { height: 860, min: 203, max: 150 },
  { height: 830, min: 202.5, max: 150 },
  { height: 760, min: 202, max: 150 },
  { height: 720, min: 201.5, max: 150 },
  { height: 0, min: 201, max: 150 },
];

const ProgressCircle = ({ count, current, color }) => {
  const [angle, setAngle] = useState(sizes[0].min);

  const onResize = () => {
    const height = document.documentElement.clientHeight;
    const size = sizes.filter((s) => s.height <= height)[0];
    const value = size.min + (current / count) * (size.max - size.min);

    setAngle(value);
  };

  useEffect(() => {
    onResize();
  }, [count, current]);

  useLayoutEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div className={styles.progressCircle}>
      <div className={styles.shadowedCircle} />
      <svg>
        <circle
          style={{
            stroke: color,
            transform: `rotate(${angle}deg)`,
          }}
        />
      </svg>
    </div>
  );
};

ProgressCircle.propTypes = {
  count: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  color: PropTypes.string,
};

ProgressCircle.defaultProps = {
  color: 'black',
};

export default ProgressCircle;
