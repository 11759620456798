import axios from 'axios';
import Cookies from 'js-cookie';
import API_BASE_API_SSO_URL from '../urls';

const apiClient = axios.create({
  baseURL: API_BASE_API_SSO_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = Cookies.get('access_token');
    const userId = Cookies.get('userId');

    return {
      ...config,
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        Userid: userId,
        'content-type': 'application/json',
      },
    };
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response.data)
);

const { get, post, put, delete: destroy } = apiClient;

export { get, post, put, destroy };
