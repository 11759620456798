import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CalculatorsContext, ErrorContext, UserContext } from '../../context';
import { formatDate } from '../../utils';
import { CREATE_INACTIVE_DRAFT } from '../../api/apollo/api';

const NewAssessment = () => {
  const navigate = useNavigate();
  const { calcName, companyId } = useParams();
  const { selectedCalculators, createCalculatorList } = useContext(CalculatorsContext);
  const { changeUserData, companies, user, setDraftId, draftId } = useContext(UserContext);
  const { setErrorAlert } = useContext(ErrorContext);

  const [createDraft] = useMutation(CREATE_INACTIVE_DRAFT);

  useEffect(() => {
    window.sessionStorage.removeItem('SELECTED_DRAFT_ID');
    setDraftId(null);
  }, []);

  const onNewDraft = () => {
    try {
      const now = new Date().toISOString().split('.')[0].replace('T', ' ');
      createDraft({
        variables: {
          input: {
            company_id: companyId,
            company_name: companies[companyId].companyName,
            email: user.email,
            legal_type_id: companies[companyId].legalTypeId,
            industrial_group_id: companies[companyId].industrialGroupId,
            sub_industry_id: companies[companyId].subIndustryId,
            company_date: companies[companyId].establishedDate
              ? formatDate(companies[companyId].establishedDate)
              : now,
            registration_country_id: companies[companyId].countryId,
            zip_code: companies[companyId].zipCode,
            address1: companies[companyId].address1,
            type: calcName,
          },
        },
      }).then((res) => {
        if (res.data.createOrGetInactiveEsgDraft) {
          setDraftId(res.data.createOrGetInactiveEsgDraft.id);
        }
      });
    } catch (_) {
      // TODO: показывать ошибку, если что-то пошло не так
      // TODO: при создании и сохранении дизейблить кнопку и показывать в ней спиннер
    }
  };

  const onErrorMessageClose = () => {
    navigate('/login', { replace: true });
  };

  useEffect(() => {
    if (!selectedCalculators || !draftId) return;
    navigate('/start', { replace: true });
  }, [selectedCalculators, draftId]);

  useEffect(() => {
    const company = companies[companyId];
    if (!company) {
      setErrorAlert('The company is not in the list available to the user.', onErrorMessageClose);
    } else {
      const isCompanyFilled = (c) =>
        c.userId === user.id &&
        !!c.address1 &&
        !!c.id &&
        !!c.companyName &&
        !!c.countryId &&
        !!c.establishedDate &&
        !!c.zipCode &&
        ((calcName !== 'global' && !!c.industrialGroupId) ||
          (calcName === 'global' && !!c.subIndustryId));
      if (company && isCompanyFilled(company)) {
        onNewDraft();
        changeUserData({ ...user, companyId });
        createCalculatorList(calcName);
      } else {
        setErrorAlert('The company details are not filled.', onErrorMessageClose);
      }
    }
  }, [companies]);

  return <></>;
};

export default NewAssessment;
