/* eslint-disable */

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@mui/material';
import UiDatePicker from '../../components/uiDatePicker/UiDatePicker';
import styles from './RegistrationForm.module.scss';

import UiTextField from '../../components/uiTextField/UiTextField';
import FileUploader from '../../components/fileUploader/FileUploader';
import MuiSelect from '../../components/muiSelect/MuiSelect';

const RegistrationForm = ({
  onChange,
  onChangeDate,
  openDisclaimer,
  numberFilter,
  data,
  // years,
  reportYear,
  dateDisable,
  factorList,
}) => (
  <>
    <Button
      className={styles.disclaimerBtn}
      size="small"
      color="lightBlue"
      onClick={openDisclaimer}
    >
      Financial data*
    </Button>
    <UiTextField
      className={clsx(styles.input, styles.wide)}
      label="Annual turnover (USD) *"
      name="turnover"
      filter={numberFilter}
      value={((data && data.turnover) ?? '').toString()}
      onChange={onChange}
      maxNumber={2147483647}
    />
    <UiTextField
      className={clsx(styles.input, styles.normal)}
      label="Net profit (USD) *"
      name="profit"
      filter={numberFilter}
      value={((data && data.profit) ?? '').toString()}
      onChange={onChange}
      maxNumber={2147483647}
    />
    <UiTextField
      className={clsx(styles.input, styles.normal)}
      label="Taxes paid for the year (USD) *"
      name="taxes"
      filter={numberFilter}
      value={((data && data.taxes) ?? '').toString()}
      onChange={onChange}
      maxNumber={2147483647}
    />
    <UiTextField
      className={clsx(styles.input, styles.wide)}
      label="Number of unique product *"
      name="uniqProduct"
      filter={numberFilter}
      value={((data && data.uniqProduct) ?? '').toString()}
      onChange={onChange}
      maxNumber={2147483647}
    />
    <UiTextField
      className={clsx(styles.input, styles.normal)}
      label="The number of employees *"
      name="employees"
      filter={numberFilter}
      value={((data && data.employees) ?? '').toString()}
      onChange={onChange}
      maxNumber={2147483647}
    />
    <UiTextField
      className={clsx(styles.input, styles.normal)}
      label="Annual productivity for each position *"
      name="productivity"
      filter={numberFilter}
      value={((data && data.productivity) ?? '').toString()}
      onChange={onChange}
      maxNumber={2147483647}
    />
    <UiDatePicker
      className={clsx(styles.input, styles.wide)}
      label="Reporting year *"
      name="reportDate"
      value={reportYear ? new Date(reportYear, 1, 1) : null}
      onChange={onChangeDate}
      minDate={new Date(2000, 1, 1)}
      maxDate={new Date(new Date().getFullYear(), 11, 31)}
      onlyYear
      disabled={dateDisable}
      required
    />
    <MuiSelect
      className={clsx(styles.input, styles.wide)}
      label="Coversion factors"
      name="factorId"
      value={data.factorId ?? ''}
      onChange={onChange}
      renderValue={(value) => factorList.find((item) => item.id === value)?.name}
      items={factorList?.map((item) => item.id) ?? []}
    />
    <div>
      <FileUploader questionCode="registration" disabled={!data.id} />
    </div>
  </>
);

RegistrationForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  openDisclaimer: PropTypes.func.isRequired,
  numberFilter: PropTypes.instanceOf(RegExp),
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  years: PropTypes.array,
  reportYear: PropTypes.number,
  dateDisable: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  factorList: PropTypes.array,
};

export default RegistrationForm;
