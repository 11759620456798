import {
  SET_CURRENT_CALC_SECTION,
  SET_QUESTIONS_DATA,
  SET_PACKAGE_MATERIALS_DATA,
  SET_PRODUCT_MATERIALS_DATA,
  SET_COMPANY_QUESTIONS_DATA,
  SET_GLOBAL_QUESTIONS_DATA,
  SET_GLOBAL_INPUT_TYPES,
  SET_CALCULATORS_LIST,
  SET_CARBON_SOURCES,
  SET_COMPANY_DATA,
  SET_CALCULATOR_ANSWER,
  SET_GLOBAL_CALCULATOR_ANSWERS,
  SET_SCORE,
  SET_LAST_VISITED_INDEX,
  SET_CALCULATOR_DATA,
  SET_CALCULATOR_NAME,
  SET_CHART_DATA,
  SET_CALCULATOR_COST,
} from '../types';

const calculatorsReducer = (state, action) => {
  switch (action.type) {
    case SET_QUESTIONS_DATA:
      return {
        ...state,
        questions: [...action.payload.questions],
        sortedData: [...action.payload.sortedData],
      };
    case SET_CURRENT_CALC_SECTION:
      return {
        ...state,
        ...action.payload,
      };
    case SET_GLOBAL_INPUT_TYPES:
      return {
        ...state,
        ...action.payload,
      };
    case SET_CALCULATOR_COST:
      return {
        ...state,
        ...action.payload,
      };
    case SET_CHART_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SET_PRODUCT_MATERIALS_DATA:
      return {
        ...state,
        productMaterials: action.payload,
      };
    case SET_PACKAGE_MATERIALS_DATA:
      return {
        ...state,
        packageMaterials: action.payload,
      };
    case SET_COMPANY_QUESTIONS_DATA:
      return {
        ...state,
        companyQuestions: [...action.payload.companyQuestions],
      };
    case SET_GLOBAL_QUESTIONS_DATA:
      return {
        ...state,
        globalQuestions: action.payload,
      };
    case SET_CALCULATORS_LIST:
      return {
        ...state,
        selectedCalculators: [...action.payload.selectedCalculators],
      };
    case SET_CALCULATOR_NAME:
      return {
        ...state,
        calculatorName: action.payload,
      };
    case SET_CARBON_SOURCES:
      return {
        ...state,
        carbonSources: { ...action.payload.carbonSources },
        chartData: action.payload.chartData,
      };
    case SET_COMPANY_DATA:
      return {
        ...state,
        company: { ...action.payload.company },
        chartData: action.payload.chartData,
      };
    case SET_CALCULATOR_ANSWER:
      return {
        ...state,
        calculatorAnswers: { ...action.payload.answers },
        chartData: action.payload.chartData,
      };
    case SET_GLOBAL_CALCULATOR_ANSWERS:
      return {
        ...state,
        globalAnswers: { ...action.payload.globalAnswers },
        chartData: action.payload.chartData,
      };
    case SET_SCORE:
      return { ...state, score: { ...action.payload } };
    case SET_LAST_VISITED_INDEX:
      return { ...state, lastVisitedIndex: action.payload };
    case SET_CALCULATOR_DATA:
      return {
        ...state,
        carbonSources: {
          product: action.payload.productAnswers || state.carbonSources.product,
          package: action.payload.packageAnswers || state.carbonSources.package,
        },
        company: action.payload.companyAnswers,
        calculatorAnswers: action.payload.calculatorAnswers,
        chartData: action.payload.chartData,
      };
    default:
      return state;
  }
};

export default calculatorsReducer;
