/* eslint-disable */

import PropTypes from 'prop-types';
import React, { useReducer, useState } from 'react';
import { fillCompany, fillProductPackage } from '../../utils';

import {
  SET_USER_DATA,
  SET_NEW_COMPANY_DATA,
  SET_USER_COMPANIES,
  SET_COUNTRIES,
  SET_INDUSTRIAL_GROUPS,
  SET_SUBINDUSTRIES,
  SET_LEGAL_TYPES,
  SET_DRAFT_ID,
  SET_DRAFTS,
  ADD_USER_COMPANY,
  SET_FACTORS,
} from '../types';

import UserContext from './userContext';
import userReducer from './userReducer';

const UserState = ({ children }) => {
  const initialUser = {
    // first step
    countryId: '',
    companyId: '',
    establishedDate: new Date(),
    legalTypeId: '',
    address1: '',
    address2: '',
    zipCode: '',
    industrialGroupId: '',
    subIndustryId: '',
    // second step
    turnover: '',
    profit: '',
    taxes: '',
    uniqProduct: '',
    productivity: '',
    employees: '',
    reportDate: new Date(),
  };
  const initialState = {
    user: {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      ...initialUser,
    },
    companies: {},
    countries: {},
    industrialGroups: {},
    subIndustries: {},
    legalTypes: {},
    draftId: '',
    draftsList: [],
  };

  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [state, dispatch] = useReducer(userReducer, initialState);

  const changeUserData = (user = {}) => {
    const newUserData = { ...user };
    dispatch({
      type: SET_USER_DATA,
      payload: newUserData,
    });
  };

  const GetCompaniesWithoutTemp = (company) =>
    Object.keys(state.companies).reduce((result, key) => {
      const res = result;
      if (key !== 'newUserCompanyId') {
        res[key] = state.companies[key];
      }

      return {
        ...res,
        [company.id]: {
          ...company,
        },
      };
    }, {});

  const changeNewCompanyData = (company = {}) => {
    const companies = GetCompaniesWithoutTemp(company);
    dispatch({
      type: SET_NEW_COMPANY_DATA,
      payload: companies,
    });
  };

  const emptyUserData = () => {
    const newUser = {
      ...state.user,
      ...initialUser,
    };

    dispatch({
      type: SET_USER_DATA,
      payload: newUser,
    });
  };

  const addUserCompany = (companyName) => {
    emptyUserData();
    const company = {
      address1: '',
      address2: '',
      companyName,
      countryId: '',
      email: '',
      establishedDate: new Date(),
      id: 'newUserCompanyId',
      industrialGroupId: '',
      subIndustryId: '',
      legalTypeId: '',
      userId: '',
      zipCode: '',
    };
    dispatch({
      type: ADD_USER_COMPANY,
      payload: company,
    });
  };

  const changeUserCompanies = (companies = []) => {
    if (!companies) return;
    const companiesObj = companies.reduce(
      (res, company) => ({
        ...res,
        [company.id]: {
          ...company,
          countryId: company.addressLocation != null ? company.addressLocation.countryId : '',
          address1: company.addressLocation != null ? company.addressLocation.address : '',
          zipCode: company.addressLocation != null ? company.addressLocation.zipCode : '',
          address2: company.address2 != null ? company.address2 : '',
        },
      }),
      {}
    );

    dispatch({
      type: SET_USER_COMPANIES,
      payload: companiesObj,
    });
  };

  const changeCountriesList = (countries = []) => {
    const countriesObj = countries.reduce(
      (res, country) => ({ ...res, [country.id]: country }),
      {}
    );

    dispatch({
      type: SET_COUNTRIES,
      payload: countriesObj,
    });
  };

  const setFactors = (factors = []) => {
    // const factorsObj = factors.reduce(
    //   (res, factor) => ({ ...res, [factor.name]: factor }),
    //   {},
    // );

    dispatch({
      type: SET_FACTORS,
      payload: factors,
    });
  };

  const changeIndustrialGroups = (industrialGroups = []) => {
    const indGroupsObj = industrialGroups.reduce(
      (res, group) => ({ ...res, [group.id]: group }),
      {}
    );

    dispatch({
      type: SET_INDUSTRIAL_GROUPS,
      payload: indGroupsObj,
    });
  };

  const changeSubIndustries = (subIndustries = []) => {
    const subIndustriesObj = subIndustries.reduce(
      (res, group) => ({ ...res, [group.id]: group }),
      {}
    );

    dispatch({
      type: SET_SUBINDUSTRIES,
      payload: subIndustriesObj,
    });
  };

  const changeLegalTypes = (legalTypes = []) => {
    const legalTypesObj = legalTypes.reduce((res, type) => ({ ...res, [type.id]: type }), {});

    dispatch({
      type: SET_LEGAL_TYPES,
      payload: legalTypesObj,
    });
  };

  const setDraftId = (id) => {
    window.sessionStorage.setItem('SELECTED_DRAFT_ID', id);
    dispatch({
      type: SET_DRAFT_ID,
      payload: id,
    });
  };

  const prepareDraft = (draft) => ({
    ...draft,
    productAnswers: draft.productAnswers
      ? fillProductPackage(JSON.parse(draft.productAnswers))
      : null,
    packageAnswers: draft.packageAnswers
      ? fillProductPackage(JSON.parse(draft.packageAnswers))
      : null,
    companyAnswers: draft.companyAnswers ? fillCompany(JSON.parse(draft.companyAnswers)) : null,
    answers: draft.answers ? JSON.parse(draft.answers) : null,
    globalAnswers: draft.globalAnswers ? JSON.parse(draft.globalAnswers) : {},
    reportDate: draft.reportDate ? new Date(draft.reportDate) : null,
    lastPosition: draft.lastPosition ? JSON.parse(draft.lastPosition) : null,
    createdAt: new Date(draft.createdAt),
    updatedAt: new Date(draft.updatedAt),
    establishedDate: new Date(draft.establishedDate),
  });

  const setDraftsList = (list) => {
    const newlist = list.map((draft) => prepareDraft(draft));
    dispatch({
      type: SET_DRAFTS,
      payload: newlist,
    });
  };

  const changeDraftsList = (list) => {
    const newList = [...list];
    dispatch({
      type: SET_DRAFTS,
      payload: newList,
    });
  };

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        companies: state.companies,
        countries: state.countries,
        industrialGroups: state.industrialGroups,
        subIndustries: state.subIndustries,
        legalTypes: state.legalTypes,
        draftId: state.draftId,
        draftsList: state.draftsList,
        selectedCompanyId,
        factors: state.factors,
        setFactors,
        setSelectedCompanyId,
        changeUserData,
        changeNewCompanyData,
        emptyUserData,
        changeUserCompanies,
        changeCountriesList,
        changeIndustrialGroups,
        changeSubIndustries,
        changeLegalTypes,
        setDraftId,
        setDraftsList,
        changeDraftsList,
        addUserCompany,
        prepareDraft,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserState;
