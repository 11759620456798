import { get, post } from './rest-client';
import { API_CALC_GET_FACTORS, API_CALC_GET_QUESTIONS } from './urls';

export const getFactors = () => get(API_CALC_GET_FACTORS);

export const getScopeQuestions = (param) => {
  const { collectionId } = param;
  return post(API_CALC_GET_QUESTIONS, {
    collection_id: collectionId,
  });
};
