/* eslint-disable */

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@mui/material';
import UiDatePicker from '../../components/uiDatePicker/UiDatePicker';

import styles from './RegistrationForm.module.scss';

import UiTextField from '../../components/uiTextField/UiTextField';
import FileUploader from '../../components/fileUploader/FileUploader';
import MuiSelect from '../../components/muiSelect/MuiSelect';

const RegistrationProject = ({
  onChange,
  openDisclaimer,
  numberFilter,
  data,
  dateDisable,
  factorList,
}) => (
  <>
    <Button
      className={styles.disclaimerBtn}
      size="small"
      color="lightBlue"
      onClick={openDisclaimer}
    >
      Basic details*
    </Button>
    <UiTextField
      className={clsx(styles.input, styles.wide)}
      label="Name *"
      name="name"
      value={((data && data.name) ?? '').toString()}
      onChange={onChange}
      inputProps={{ maxLength: 255 }}
    />
    <UiTextField
      className={clsx(styles.input, styles.normal)}
      label="Quantity *"
      name="uniqProduct"
      value={((data && data.uniqProduct) ?? '').toString()}
      onChange={onChange}
      filter={numberFilter}
      maxNumber={2147483647}
    />
    <UiDatePicker
      className={clsx(styles.input, styles.wide)}
      label="Start period"
      name="reportDate"
      value={(data && data.reportDate) ?? null}
      onChange={onChange}
      minDate={new Date(2000, 1, 1)}
      maxDate={data.reportDateEnd ?? new Date(new Date().getFullYear(), 11, 31)}
      disabled={dateDisable}
      required={!!data.reportDate || !!data.reportDateEnd}
      clearable
    />
    <UiDatePicker
      className={clsx(styles.input, styles.wide)}
      label="End period"
      name="reportDateEnd"
      value={(data && data.reportDateEnd) ?? null}
      onChange={onChange}
      minDate={data.reportDate ?? new Date(2000, 1, 1)}
      maxDate={new Date(new Date().getFullYear(), 11, 31)}
      disabled={dateDisable}
      required={!!data.reportDate || !!data.reportDateEnd}
      clearable
    />
    <MuiSelect
      className={clsx(styles.input, styles.wide)}
      label="Coversion factors"
      name="factorId"
      value={data.factorId ?? ''}
      onChange={onChange}
      renderValue={(value) => factorList.find((item) => item.id === value)?.name}
      items={factorList?.map((item) => item.id) ?? []}
    />
    <div>
      <FileUploader questionCode="registration" disabled={!data.id} />
    </div>
  </>
);

RegistrationProject.propTypes = {
  onChange: PropTypes.func.isRequired,
  openDisclaimer: PropTypes.func.isRequired,
  numberFilter: PropTypes.instanceOf(RegExp),
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  dateDisable: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  factorList: PropTypes.array,
};

export default RegistrationProject;
