/* eslint-disable */

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Modal } from 'tt-ui-lib/core';
import Chart from '../../../../components/chart/Chart';
import styles from './PreliminaryResults.module.scss';
import { GET_PRELIMINARY_ASSESSMENT } from '../../../../api/apollo/api';
import { CalculatorsContext } from '../../../../context';

const PreliminaryResults = ({ opened, closeModalFunc, scope, draftId }) => {
  const [result, setResult] = useState(null);
  const { chartData } = useContext(CalculatorsContext);

  const [getResultDraft] = useMutation(GET_PRELIMINARY_ASSESSMENT, {
    variables: { id: draftId },
  });

  useEffect(() => {
    if (opened) {
      getResultDraft().then((res) => {
        setResult(res.data.getPreliminaryAssessment);
      });
    }
    return () => {
      setResult(null);
    };
  }, [opened]);

  return (
    <Modal
      onClose={closeModalFunc}
      open={opened}
      containerClassName={styles.modalRoot}
      className={styles.modal}
      fullScreenOnResponse
      closeOnlyByControls
      title={`Scope ${scope - 1} preliminary results`}
    >
      <div>
        <div className={styles.wrap}>
          {`Scope ${scope - 1} score - ${result?.companyTotal ?? 0}`}
        </div>
        <div className={styles.chartArea}>
          <Chart chartData={chartData} hasDesktopFormat={false} />
        </div>
      </div>
    </Modal>
  );
};

PreliminaryResults.propTypes = {
  scope: PropTypes.string,
  opened: PropTypes.bool,
  closeModalFunc: PropTypes.func,
  draftId: PropTypes.string,
};

export default PreliminaryResults;
