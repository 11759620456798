/* eslint-disable */
import clsx from 'clsx';
import PropTypes, { string } from 'prop-types';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { Grid, Button, Tooltip } from '@mui/material';
import { CalculatorsContext, UserContext, ApiMethodContext, ErrorContext } from '../../../context';

import { UPDATE_DRAFT } from '../../../api/apollo/api';

import Form from '../../../components/form/Form';
import Pagination from '../../../components/pagination/Pagination';

import AddButton from './AddButton';
import Component6Fields from './components/Component6Fields';
import QuestionButton from '../../../components/questionButton/QuestionButton';

import { ReactComponent as HelpSvg } from '../../../assets/icons/help.svg';
import styles from './index.module.scss';
import CalcModal from '../../../components/CalcModal/CalcModal';
import FileUploader from '../../../components/fileUploader/FileUploader';
import CalculationCost from '../../../components/calculationCost/CalculationCost';
import { getScopeQuestions } from '../../../api/rest/rest-api';
import AllQuestionsModal from './components/AllQuestionsModal';
import PreliminaryResults from './components/PreliminaryResults';

const Company = ({ scope, setQuestionIndex }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { idx } = useParams();

  const [previousScope, setPreviousScope] = useState(scope);

  const [updateDraft] = useMutation(UPDATE_DRAFT);

  const { setErrorAlert } = useContext(ErrorContext);
  const { saveDraftData, completeDraft } = useContext(ApiMethodContext);
  const { draftId, draftsList, factors } = useContext(UserContext);
  const {
    company,
    chartData,
    setDraftData,
    selectedCalculators,
    setCompanyData,
    setCalculatorsList,
    companyQuestions,
    isAnyAnswerExists,
    changeCompanyQuestionsData,
  } = useContext(CalculatorsContext);

  const [isFileModalOpened, setIsFileModalOpened] = useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [preliminaryModalOpened, setPreliminaryModalOpened] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [index, setIndex] = useState(0);

  const [scopeQuestions, setScopeQuestions] = useState([]);
  const [question, setQuestion] = useState({});
  const [allQuestionModalOpened, setAllQuestionModalOpened] = useState(false);

  const { id, fields } = question;
  const activities = [...(company[id] ?? [])];

  // get factors for loading questions. if factorId is not set, get factorId by reportDate
  const getFactorId = (draft) => {
    const { factorId, reportDate, reportDateEnd } = draft;
    if (factorId) return factorId;
    return factors.find(
      (f) => f.year === (reportDateEnd?.getFullYear() ?? reportDate?.getFullYear())
    )?.id; // TODO remove
  };

  // initialize new activity with null values by the available fields of the question
  const initNewActivity = () => fields.reduce((obj, item) => ({ ...obj, [item]: null }), {});

  // RECURSIVE FUNCTION!!!
  // The depth of the question tree is a maximum of 5 levels as of 04/15/2024.
  // mark the features of questions (analize) to disable the fields on the form
  // features - single-length fields, question tree is shorter than an array of fields
  const markFeature = (item, flds, i) => {
    const items = item[flds[i]];

    // case when question tree is shorter than an array of fields
    if (!items) return { disabled: { [item.id]: flds[i] } };

    // self result - is the field single-length in this item
    const selfRes = { [flds[i]]: items.length === 1 };

    // child result - is the field single-length in the all child item
    const childRes =
      i === flds.length - 1
        ? {} // last field in the array has no child
        : items.reduce((res, el) => {
            const itemRes = markFeature(el, flds, i + 1);
            return {
              ...itemRes,
              [flds[i + 1]]: selfRes[flds[i]] ? itemRes[flds[i + 1]] : false,
              disabled: {
                ...res.disabled,
                ...itemRes.disabled,
              },
            };
          }, {});
    return {
      ...selfRes,
      ...childRes,
    };
  };

  // mark the features of all questions
  const findAllFeatureItem = (data) => {
    const newData = data.reduce((scpResult, scp) => {
      const newQsts = scp.questions.reduce((qstsResult, qst) => {
        const newQst = {
          ...markFeature(qst, [...qst.fields], 0),
        };
        return [...qstsResult, { ...qst, feature: newQst }];
      }, []);
      return [...scpResult, { ...scp, questions: newQsts }];
    }, []);
    return newData;
  };

  // get questions by factorId
  useEffect(() => {
    if (!draftId) return;
    const currentDrft = draftsList.find((draft) => draft.id === draftId);
    const factorId = getFactorId(currentDrft);
    if (factorId) {
      getScopeQuestions({ collectionId: factorId })
        .then((res) => {
          const { data } = res;
          const newQuestions = findAllFeatureItem([...data]);
          changeCompanyQuestionsData(newQuestions);
        })
        .catch((err) => {
          setErrorAlert(err.message);
        });
    }
  }, [draftId]);

  useEffect(() => {
    if (!draftId || !draftsList.length > 0 || !companyQuestions) return;
    const draft = draftsList.find((d) => d.id === draftId);
    setDraftData(draft);
  }, [draftId, draftsList, companyQuestions]);

  // set questions by scope
  useEffect(() => {
    const newScopeQuestions =
      companyQuestions && companyQuestions[scope - 1] ? companyQuestions[scope - 1].questions : [];
    setScopeQuestions([...newScopeQuestions]);
  }, [companyQuestions, scope]);

  // set question by index
  useEffect(() => {
    const newQquestion = scopeQuestions[index] ?? {};
    setQuestion({ ...newQquestion });
  }, [scopeQuestions, index]);

  // init new result activity if it is not exist
  useEffect(() => {
    if (!id || !fields || !!company[id]) return;
    const newActivities = [initNewActivity()];
    setCompanyData(id, newActivities);
  }, [company, question]);

  // set index by url
  useEffect(() => {
    setIndex(idx - 1 < 0 ? 0 : idx - 1);
  }, [idx]);

  // set nember of questions by scope and index for the progress bar (colored arc)
  useEffect(() => {
    if (!companyQuestions) return;
    const prev = scope > 1 ? [...Array(scope - 1).keys()] : [];
    const count = prev.reduce((res, v) => res + companyQuestions[v].questions.length, 0);
    setQuestionIndex(count + index);
  }, [index, scope]);

  useEffect(() => {
    setPreviousScope(scope);
    if (scope === 0 || scope <= previousScope) return;
    setPreliminaryModalOpened(true);
  }, [scope]);

  // save data to the draft with filtering empty fields
  const onSaveAnswer = async () => {
    const answers = Object.keys(company).reduce((result, key) => {
      const components = (company[key] ?? []).filter((component) => component.filled);

      return components.length ? { ...result, [key]: components } : result;
    }, {});

    try {
      await updateDraft({
        variables: {
          input: {
            id: draftId,
            company_answers: JSON.stringify(answers),
            last_position: JSON.stringify(pathname),
            state_graphics: JSON.stringify(chartData),
          },
        },
      });
    } catch (_) {
      // TODO: показывать ошибку, если запрос не прошёл
      // см. комментарий в Local.jsx
    }
  };

  // get index of the current calculator in the selectedCalculators array
  const getCalcIndex = () =>
    selectedCalculators.findIndex((calculator) => calculator.name === `scope${scope}`);

  // get the number of filled activities
  const activityFilledCount = () => {
    if (!question || !activities) return -1;

    return activities.filter((act) => fields.reduce((res, k) => res && !!act[k], true)).length;
  };

  // get the list of items by the field
  const getItemsList = (activity, field, currentQuestion) => {
    if (!currentQuestion) return [];
    const { feature, fields: currentFields } = currentQuestion ?? {};

    if (!currentFields || !currentFields.length) return [];
    // get the deep of the tree to the current field
    const sliced = currentFields.slice(0, currentFields.indexOf(field) + 1);
    // get the list of items by the field
    const itemsRes = sliced.reduce((items, f) => {
      if (f === field || items?.length === 0) {
        return items;
      }
      const newItem = feature[f] ? items[f][0] : items[f].find((el) => el.id === activity[f]) ?? [];
      return newItem;
    }, currentQuestion);
    return itemsRes[field] ?? [];
  };

  const getLabel = (field) => {
    switch (field) {
      case 'f1':
        return 'Activity';
      case 'f2':
        return 'Type';
      case 'f3':
        return 'Size';
      case 'f4':
        return 'Additional';
      case 'f5':
        return 'Additional';
      default:
        return '';
    }
  };

  const getItemNames = (activity, currentQuestion) => {
    const { fields: currentFields } = currentQuestion;
    if (!currentFields || !currentFields.length) return {};

    return currentFields.reduce((res, field) => {
      const items = getItemsList(activity, field, currentQuestion);
      const item = items.find((el) => el.id === activity[field]);
      return { ...res, [field]: item?.name ?? '' };
    }, {});
  };

  const onBackToListOfIssues = () => {
    navigate(`/drafts`);
  };

  const onBackClick = async () => {
    await onSaveAnswer();
    const calcIndex = getCalcIndex();
    if (index === 0) {
      if (calcIndex < 0) {
        setCalculatorsList([]);
        navigate('/');
      } else if (calcIndex === 0) {
        navigate('/drafts');
      } else {
        if (selectedCalculators[calcIndex - 1].name.includes('scope')) {
          setIndex(companyQuestions[scope - 2].questions.length - 1);
        }
        navigate(
          `${selectedCalculators[calcIndex - 1].clearUrl}/${
            companyQuestions[scope - 2].questions.length
          }`
        );
      }
    } else {
      // setIndex(index - 1);
      navigate(`${selectedCalculators[calcIndex].clearUrl}/${parseInt(idx, 10) - 1}`);
    }
  };

  const onNextClick = async () => {
    await onSaveAnswer();
    const calcIndex = getCalcIndex();
    if (index === scopeQuestions.length - 1) {
      if (calcIndex < 0) {
        setCalculatorsList([]);
        navigate('/');
      } else if (calcIndex === selectedCalculators.length - 1) {
        if (isAnyAnswerExists()) {
          await completeDraft();
          navigate(`/buy_credits/${draftId}`);
        } else navigate('/drafts');
      } else {
        if (selectedCalculators[calcIndex + 1].name.includes('scope')) {
          setIndex(0);
        }
        navigate(selectedCalculators[calcIndex + 1].url);
      }
    } else {
      // setIndex(index + 1);
      navigate(`${selectedCalculators[calcIndex].clearUrl}/${parseInt(idx, 10) + 1}`);
    }
  };

  // add new activity
  const addActivity = () => {
    const newActivities = [...activities, { ...initNewActivity() }];
    setCompanyData(id, newActivities);

    setTimeout(() => {
      const activitiesElements = document.getElementsByClassName('activity');
      activitiesElements[activitiesElements.length - 1].scrollIntoView({
        behavior: 'smooth',
      });
    });
  };

  // change activity data
  const changeActivity = (actIndex, field, value) => {
    // features - single-length fields, question tree is shorter than an array of fields
    // analized in markFeature() function
    const { feature } = question;
    // key-value pairs {id: field} of the fields that should be disabled
    const { disabled } = feature;
    const newActivities = [...activities];
    const activity = newActivities[actIndex];

    // the fields of the current activity are divided into two parts by the current field
    const lowLevelFields = fields.includes(field) ? fields.slice(fields.indexOf(field)) : [];
    const upLevelFields = fields.includes(field) ? fields.slice(0, fields.indexOf(field)) : fields;

    // the data of the current activity is cleared from the current field to the end
    const clearActivity = lowLevelFields.reduce(
      (obj, item) => ({ ...obj, [item]: null, filled: false }),
      { ...activity }
    );
    const newActivity = { ...clearActivity, [field]: value };

    // check if the upper level fields of the current activity are filled
    const isLevelUpFilled = upLevelFields.reduce((res, f) => res && !!activity[f], true);

    // if the upper level fields of the current activity aren't filled...
    if (!isLevelUpFilled) {
      // ...check if the current field is single-length
      const isLevelUpSingle = upLevelFields.reduce((res, f) => res && feature[f], true);
      // if the current field is single-length...
      if (isLevelUpSingle) {
        // ...get the id of the single-length field
        upLevelFields.forEach((f) => {
          const { id: singlId } = getItemsList(newActivity, f, question)[0];
          newActivity[f] = singlId;
        });
      }
    }

    // get the id and name of the disabled field
    const disabledkey = Object.keys(newActivity).find((key) => !!disabled[newActivity[key]]);
    const disabledField = disabledkey ? disabled[newActivity[disabledkey]] : null;
    // get the fields that should be filled to validate the data except the disabled field
    const fieldsForValid = disabledField ? fields.slice(0, fields.indexOf(disabledField)) : fields;
    // check if the data is filled
    const isDataFilled =
      fieldsForValid.reduce((res, f) => res && !!newActivity[f], true) && !!newActivity.data;

    // get the uom_id of the current activity by the last field that should be filled
    const uom = newActivity[fieldsForValid.findLast((el) => el)];
    newActivities[actIndex] = {
      ...newActivity,
      filled: isDataFilled,
      uom_id: uom ?? null,
    };
    setCompanyData(id, newActivities);
  };

  const removeActivity = () => {
    const newActivities = activities.filter((el, indx) => indx !== deleteIndex);
    setCompanyData(id, newActivities);
  };

  // check if the new activity is allowed for disable the button "Add Activity"
  const isNewActivityAllowed = () => {
    if (!question || !activities.length) return false;
    return activities[activities.length - 1]?.filled;
  };

  const closeFileModal = () => {
    setIsFileModalOpened(false);
  };

  const openDeleteModal = (indx) => {
    setDeleteIndex(indx);
    setIsDeleteModalOpened(true);
  };

  const closeDeleteModal = () => {
    setDeleteIndex(null);
    setIsDeleteModalOpened(false);
  };

  const getGuestionCode = () =>
    scopeQuestions && scopeQuestions[index] ? scopeQuestions[index].id : '';

  return (
    <>
      {fields && company && allQuestionModalOpened && (
        <AllQuestionsModal
          opened={allQuestionModalOpened}
          closeModalFunc={() => setAllQuestionModalOpened(false)}
          questionId={id}
          companyQuestions={companyQuestions}
          answers={company}
          getItemNames={getItemNames}
          getLabel={getLabel}
        />
      )}
      {preliminaryModalOpened && (
        <PreliminaryResults
          opened={preliminaryModalOpened}
          closeModalFunc={() => setPreliminaryModalOpened(false)}
          draftId={draftId}
          scope={previousScope}
        />
      )}
      <CalcModal
        opened={isDeleteModalOpened}
        closeModalFunc={closeDeleteModal}
        headerContent="Delete component"
        okBtnText="Delete"
        okFunc={removeActivity}
        needAgreement={false}
        backBtnText="Cancel"
      >
        Do you really want to delete activity №{deleteIndex + 1}?
      </CalcModal>
      <CalcModal
        opened={isFileModalOpened}
        closeModalFunc={closeFileModal}
        headerContent="Upload your confirmation docs"
        okBtnText="Ok"
        needAgreement={false}
        backBtnText="Back"
        backFunc={closeFileModal}
      >
        <FileUploader questionCode={getGuestionCode()} />
      </CalcModal>
      <Grid
        item
        container
        wrap="nowrap"
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        className={styles.formContainer}
      >
        <div>
          <div>
            <QuestionButton isAnsvered={activityFilledCount() > 0} type="isAnsvered" />
            <QuestionButton
              action={onBackToListOfIssues}
              type="redirect"
              label="Back to list of issues"
            />
            <QuestionButton action={saveDraftData} type="save" label="Save & complete later" />
          </div>
          <Form
            headerContent={
              <>
                <div className={clsx(styles.headerContainer, styles.companyHeaderContainer)}>
                  {`${question?.name?.substring(0, question?.name?.lastIndexOf(' '))} `}
                  <span>
                    {question?.name?.substring(question.name.lastIndexOf(' ') + 1)}
                    <div className={styles.icons}>
                      {question?.name?.startsWith('WTT') && (
                        <Tooltip title="Well-to-tank" enterTouchDelay={0}>
                          <HelpSvg className={clsx(styles.infoIcon, styles.topIcon)} />
                        </Tooltip>
                      )}
                    </div>
                  </span>
                </div>
              </>
            }
          >
            {activities &&
              activities.map((activity, indx) => (
                <Component6Fields
                  key={indx}
                  question={question}
                  activity={activity}
                  index={indx}
                  onChange={changeActivity}
                  onRemove={openDeleteModal}
                  getItemsList={getItemsList}
                  isLast={indx === activities.length - 1}
                  getLabel={getLabel}
                />
              ))}
            <div className={styles.docButtonCont}>
              <FileUploader questionCode={getGuestionCode()} disabled={false} />
              <AddButton
                className={styles.addButton}
                text="Add Activity"
                onClick={addActivity}
                isDisabled={!isNewActivityAllowed()}
              />
            </div>
          </Form>
          <CalculationCost />
        </div>
        <div className={styles.navigation}>
          <Button
            variant="outlined"
            style={{
              visibility: getCalcIndex() > 0 || index > 0 ? 'visible' : 'hidden',
            }}
            onClick={onBackClick}
          >
            BACK
          </Button>
          <Button variant="outlined" onClick={() => setAllQuestionModalOpened(true)}>
            view all questions
          </Button>
          <Pagination
            activePage={index + 1}
            pagesCount={!scopeQuestions ? 0 : scopeQuestions.length}
          />

          <Button variant="contained" onClick={onNextClick}>
            {getCalcIndex() >= selectedCalculators.length - 1 && index === scopeQuestions.length - 1
              ? 'FINISH'
              : 'NEXT'}
          </Button>
        </div>
      </Grid>
    </>
  );
};

Company.propTypes = {
  scope: PropTypes.string.isRequired,
  setQuestionIndex: PropTypes.func,
};

Company.defaultProps = {
  setQuestionIndex: () => {},
};

export default Company;
