/* eslint-disable */

import PropTypes from 'prop-types';
import React, { useReducer } from 'react';

import { SET_APP_LOADING, SET_APP_LOADED } from '../types';

import AppContext from './appContext';
import appReducer from './appReducer';

const AppState = ({ children }) => {
  const initialState = {
    loading: null,
    loaded: null,
  };

  const [state, dispatch] = useReducer(appReducer, initialState);

  const changeAppLoading = (loading = null) => {
    dispatch({
      type: SET_APP_LOADING,
      payload: loading > 0.98 ? 1 : loading,
    });
  };

  const changeAppLoaded = (loaded = null) => {
    dispatch({
      type: SET_APP_LOADED,
      payload: loaded,
    });
  };

  const setItemInStorage = (name, object) => {
    window.sessionStorage.setItem(name, JSON.stringify(object));
  };

  const getItemFromStorage = (name) => window.sessionStorage.getItem(name);

  const removeItemFromStorage = (name) => {
    window.sessionStorage.removeItem(name);
  };

  const clearStorage = () => {
    window.sessionStorage.clear();
  };

  return (
    <AppContext.Provider
      value={{
        loading: state.loading,
        changeAppLoading,
        loaded: state.loaded,
        changeAppLoaded,
        setItemInStorage,
        getItemFromStorage,
        removeItemFromStorage,
        clearStorage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppState;
