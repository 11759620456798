/* eslint-disable */

import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_DRAFT } from '../api/apollo/api';
import { formatDate } from '../utils';

import UserContext from '../context/User/userContext';
import CalculatorsContext from '../context/Calculators/calculatorsContext';
import ApiMethodContext from './apiMethodContext';

const ApiMethodService = ({ children }) => {
  const [updateDraft] = useMutation(UPDATE_DRAFT);

  const { draftId, draftsList } = useContext(UserContext);

  const { state, calculatorName, chartData } = useContext(CalculatorsContext);

  const GetCompanyData = (company) =>
    Object.keys(company).reduce((result, key) => {
      const components = company[key]
        .filter((component) => {
          const keys = Object.keys(component).filter(
            (ckey) => !['isNotAvailable', 'info', 'filled'].includes(ckey)
          );
          return !!component.info || keys.reduce((res, k) => res && !!component[k], true);
        })
        .map((component) => {
          const cloned = {
            ...component,
          };
          delete cloned.scopeId;
          delete cloned.filled;
          delete cloned.isNotAvailable;
          return cloned;
        });

      return {
        ...result,
        [key]: components,
      };
    }, {});

  const completeDraft = async () => {
    try {
      await updateDraft({
        variables: {
          input: {
            id: draftId,
            completed_at: formatDate(new Date()),
          },
        },
      });
    } catch (_) {
      // TODO: показывать ошибку, если запрос не прошёл
      // см. комментарий в Local.jsx
    }
  };

  // TODO: переделать когда будут возвращать верхние кнопки сохранения
  const saveDraftData = async () => {
    const draft = draftsList.find((d) => d.id === draftId);
    const localAnswers = state.calculatorAnswers;
    const companyAnswers = GetCompanyData(state.company);

    if (draftId) {
      try {
        await updateDraft({
          variables: {
            input: {
              id: draftId,
              user_id: draft.userId,
              company_id: draft.companyId,
              company_name: draft.companyName,
              email: draft.email,
              legal_type_id: draft.legalTypeId,
              industrial_group_id: draft.industrialGroupId,
              company_date: formatDate(draft.establishedDate),
              registration_country_id: draft.countryId,
              zip_code: draft.zipCode,
              address1: draft.address1,
              address2: draft.address2,
              annual_turnover: draft.turnover,
              net_profit: draft.profit,
              year_taxes: draft.taxes,
              number_unique_products: draft.uniqProduct,
              annual_productivity_each_position: draft.productivity,
              employees_number: draft.employees,
              period_report_date: formatDate(draft.reportDate.getFullYear()),
              type: calculatorName,
              answers: JSON.stringify(localAnswers),
              company_answers: JSON.stringify(companyAnswers),
              last_position: JSON.stringify(draft.lastPosition),
              state_graphics: JSON.stringify(chartData),
            },
          },
        });
      } catch (_) {
        // TODO: показывать ошибку, если запрос не прошёл
        // см. комментарий в Local.jsx
      }
    }
  };

  return (
    <ApiMethodContext.Provider value={{ saveDraftData, completeDraft }}>
      {children}
    </ApiMethodContext.Provider>
  );
};

ApiMethodService.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApiMethodService;
