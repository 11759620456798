import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';

import { CalculatorsContext } from '../../context';
import Chart from '../../components/chart/Chart';
import CalcModal from '../../components/CalcModal/CalcModal';
import styles from '../pages.module.scss';

import {
  SelfAssessmentDisclaimerText,
  SelfAssessmentInfoText,
  CalculatorsListData,
  CALC_TYPES,
} from '../../constants';

const Start = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { fullChartData, calculatorName } = useContext(CalculatorsContext);
  const [isDisclaimerOpened, setIsDisclaimerOpened] = useState(false);

  const isESG = [
    CALC_TYPES.LOCAL,
    CALC_TYPES.GLOBAL,
    CALC_TYPES.CARBON,
    CALC_TYPES.PROJECT,
  ].includes(calculatorName);

  const openDisclaimer = () => {
    setIsDisclaimerOpened(true);
  };
  const closeDisclaimer = () => {
    setIsDisclaimerOpened(false);
  };
  const openCalculator = () => {
    const url = pathname === 'solo_start' ? '/solo_registration' : '/registration';
    navigate(url, { replace: true });
  };

  useEffect(() => {
    if (!calculatorName) return;
    if (!CalculatorsListData[calculatorName]) navigate('/404', { replace: true });
  }, []);

  return (
    <Grid item container alignItems="stretch" justifyContent="center" className={styles.container}>
      {isESG && (
        <div className={styles.chartArea}>
          <Chart chartData={fullChartData} hasDesktopFormat />
        </div>
      )}
      <Grid
        item
        container
        direction="column"
        className={clsx(styles.textArea, !isESG && styles.noChart)}
      >
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: SelfAssessmentInfoText[calculatorName],
          }}
        />
        <Button form="rounded" variant="contained" onClick={openDisclaimer}>
          Start self-assessment
        </Button>
      </Grid>

      <CalcModal
        opened={isDisclaimerOpened}
        closeModalFunc={closeDisclaimer}
        okFunc={openCalculator}
        headerContent="Disclaimer"
        okBtnText="Continue"
        needAgreement
      >
        <div
          dangerouslySetInnerHTML={{
            __html: SelfAssessmentDisclaimerText[calculatorName]
              ? SelfAssessmentDisclaimerText[calculatorName]
              : SelfAssessmentDisclaimerText.base,
          }}
        />
      </CalcModal>
    </Grid>
  );
};

export default Start;
