import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import Header from './components/header/Header';
import Loading from './components/loading/Loading';
import Start from './views/start/Start';
import NotFound from './views/notFound/NotFound';
import Finish from './views/finish/Finish';
import NewAssessment from './views/ttAssessment/NewAssessment';
import EditAssessment from './views/ttAssessment/EditAssessment';
import Registration from './views/registration/Registration';
import CalculatorsRouter from './views/calculators/CalculatorsRouter/CalculatorsRouter';
import ProtectedRoute from './views/ttAssessment/ProtectedRoute';
import LoadedDraftRoute from './views/ttAssessment/LoadedDraftRoute';

import { AppContext, CalculatorsContext, UserContext } from './context';

import {
  GET_COMPANIES,
  GET_COUNTRIES,
  GET_INDUSTIAL_GROUPS,
  GET_SUBINDUSTRIES,
  GET_LEGAL_TYPES,
  GET_CALCULATOR_QUESTIONS,
  GET_OWN_DRAFTS,
  GET_GLOBAL_QUESTIONS_DATA,
} from './api/apollo/api';

import { snakeToCamel } from './utils';
import { GetTypesAsArray } from './constants';
import { getFactors } from './api/rest/rest-api';

const initialLoaderFlags = {
  userDraftsData: false,
  companiesData: false,
  countriesData: false,
  indGroupsData: false,
  subIndData: false,
  legalTypesData: false,
  questionsData: false,
  globalQuestions: false,
  factors: false,
};

const AppRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    changeUserCompanies,
    changeCountriesList,
    changeIndustrialGroups,
    changeSubIndustries,
    changeLegalTypes,
    setDraftsList,
    setDraftId,
    draftsList,
    setFactors,
    user,
  } = useContext(UserContext);

  const {
    changeQuestionsData,
    changeGlobalQuestionsData,
    createCalculatorList,
    chartCreated,
    setDraftData,
    draftId,
  } = useContext(CalculatorsContext);

  const { loaded, changeAppLoading, changeAppLoaded, clearStorage, getItemFromStorage } =
    useContext(AppContext);

  const [loadingNumber, setLoadingNumber] = useState(initialLoaderFlags);
  const [startLoading, setStartLoading] = useState(true);
  const [tryRestore, setTryRestore] = useState(false);

  useEffect(() => {
    if (
      location.pathname.includes('/startCalculator') ||
      location.pathname.includes('/editCalculator')
    ) {
      clearStorage();
    } else {
      setTryRestore(true);
    }
  }, []);

  useEffect(() => {
    changeAppLoading(0);
    changeAppLoaded(false);
    setLoadingNumber(initialLoaderFlags);
    setStartLoading(true);
  }, []);

  const increaseLoading = (respName) => {
    setLoadingNumber((obj) => ({
      ...obj,
      [respName]: true,
    }));
  };

  const [getQuestions, { data: questionsData }] = useLazyQuery(GET_CALCULATOR_QUESTIONS);
  const [getUserDrafts, { data: userDraftsData }] = useLazyQuery(GET_OWN_DRAFTS);

  // users data
  const [getCompaniesData, { data: companiesData }] = useLazyQuery(GET_COMPANIES);

  const [getCountriesData, { data: countriesData }] = useLazyQuery(GET_COUNTRIES);
  const [getIndGroupsData, { data: indGroupsData }] = useLazyQuery(GET_INDUSTIAL_GROUPS);
  const [setSubIndData, { data: subIndData }] = useLazyQuery(GET_SUBINDUSTRIES);
  const [getLegalTypesData, { data: legalTypesData }] = useLazyQuery(GET_LEGAL_TYPES);

  const [getGlobalQuestions, { data: globalQuestions }] = useLazyQuery(GET_GLOBAL_QUESTIONS_DATA);

  const getFactorsList = () => {
    getFactors().then((res) => {
      setFactors(res?.data ?? []);
      increaseLoading('factors');
    });
  };

  useEffect(() => {
    if (!startLoading || !user) return;
    getCountriesData();
    getIndGroupsData();
    setSubIndData();
    getLegalTypesData();
    getGlobalQuestions();
    getQuestions();
    getUserDrafts();
    setStartLoading(false);
    getFactorsList();
  }, [startLoading, user]);

  useEffect(() => {
    if (!user?.id) return;
    getCompaniesData({ variables: { id: user.id } });
  }, [user]);

  useEffect(() => {
    const keys = Object.keys(loadingNumber);
    const count = keys.reduce((res, k) => res + (loadingNumber[k] ? 1 : 0), 0);
    changeAppLoading(count / keys.length);
  }, [loadingNumber]);

  useEffect(() => {
    if (loaded && tryRestore && !draftId && draftsList.length > 0) {
      const newDraftId = getItemFromStorage('SELECTED_DRAFT_ID');
      if (newDraftId) {
        const draft = draftsList.find((d) => d.id === newDraftId);
        if (draft) {
          const name = GetTypesAsArray().find((n) => draft.type === n);
          if (name && newDraftId) {
            setDraftId(newDraftId);
            createCalculatorList(name);
          } else {
            navigate('/drafts');
          }
        } else {
          navigate('/drafts');
        }
      }
    }
  }, [loaded]);

  useEffect(() => {
    if (!chartCreated) return;
    const newDraftId = getItemFromStorage('SELECTED_DRAFT_ID');
    const draft = draftsList.find((d) => d.id === newDraftId);
    if (!draft) return;
    setDraftData(draft);
  }, [chartCreated]);

  useEffect(() => {
    if (userDraftsData) {
      const newDraftList = userDraftsData.getOwnDrafts;
      increaseLoading('userDraftsData');
      setDraftsList(newDraftList);
    }
  }, [userDraftsData]);

  useEffect(() => {
    if (companiesData) {
      increaseLoading('companiesData');
      changeUserCompanies(companiesData.showAllCompaniesByUserId);
    }
  }, [companiesData]);

  useEffect(() => {
    if (countriesData) {
      increaseLoading('countriesData');
      changeCountriesList(countriesData.showCountries);
    }
  }, [countriesData]);

  useEffect(() => {
    if (indGroupsData) {
      increaseLoading('indGroupsData');
      changeIndustrialGroups(indGroupsData.showIndustryGroups);
    }
  }, [indGroupsData]);

  useEffect(() => {
    if (subIndData) {
      increaseLoading('subIndData');
      changeSubIndustries(subIndData.showSubIndustries);
    }
  }, [subIndData]);

  useEffect(() => {
    if (legalTypesData) {
      increaseLoading('legalTypesData');
      changeLegalTypes(legalTypesData.showCompanyLegalTypes);
    }
  }, [legalTypesData]);

  useEffect(() => {
    if (questionsData) {
      increaseLoading('questionsData');
      changeQuestionsData(questionsData.questions);
    }
  }, [questionsData]);

  useEffect(() => {
    const filterEmpty = (data) => {
      const newData = data.map((dimention) => ({
        ...dimention,
        categories: dimention.categories
          .map((category) => ({
            ...category,
            questions: category.questions.filter((question) => question.subQuestions.length > 0),
          }))
          .filter((category) => category.questions.length > 0),
      }));
      return newData;
    };
    if (globalQuestions) {
      increaseLoading('globalQuestions');
      const { GlobalQuestions } = globalQuestions.getGlobalQuestions;
      const newQuestions = snakeToCamel(JSON.parse(GlobalQuestions));
      const filteredQuestions = filterEmpty(newQuestions);
      changeGlobalQuestionsData(filteredQuestions);
    }
  }, [globalQuestions]);

  return (
    <>
      {loaded && (
        <>
          <Header />
          <main>
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route exact path="/editCalculator/:draftId" element={<EditAssessment />} />
                <Route
                  exact
                  path="/startCalculator/:calcName/:companyId"
                  element={<NewAssessment />}
                />
                <Route element={<LoadedDraftRoute />}>
                  <Route exact path="/start" element={<Start />} />
                  <Route exact path="/registration" element={<Registration />} />
                  <Route path="/calculators/*" element={<CalculatorsRouter />} />
                  <Route exact path="/finish/*" element={<Finish />} />
                </Route>
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
        </>
      )}
      <Loading />
    </>
  );
};

export default AppRouter;
